<!-- 教学素材 -- 学科知识点 -->
<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="search = null"
        @onChangePage="changeCurrentPage"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'subject_id',
          tag: 'select',
          placeholder: '筛选学科',
          options: [],
          label: 'subject_name',
          value: 'id'
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'category',
          label: '知识点分类'
        },
        {
          prop: 'content',
          label: '知识点内容'
        },
        {
          prop: 'subject',
          label: '学科',
        },
        {
          prop: 'source',
          label: '数据来源'
        },
        {
          prop: 'creator',
          label: '创建人',
          width: '200'
        },
        {
          prop: 'created_at',
          label: '创建时间',
          width: '200'
        }
      ],
      search: null,
      total: 0,
      // 表格中的数据
      tableData: [],
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getConfig()
  },
  activated() {
    this.getData()
    this.getConfig()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },

    getConfig(){
      this.$_axios2.get('api/question/knowledge/subject').then(res => {
        this.searchConfig[0].options = res.data.data || []
      })
    },

    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('api/question/knowledge/list', {params: {...search, page}}).then(res => {
        console.log(res)
        let {data} = res.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}
</style>
